import React from 'react';
import { IconSizeEnum, SolidIcon } from '@edgenuity/cns-ui';
import { IconButton, SnackbarOrigin } from '@mui/material';
import { SolidIconDefinitions } from '@edgenuity/cns-ui/components/il-icon/solid-icon-definitions';

const snackbarPosition: SnackbarOrigin = {
	horizontal: 'center',
	vertical: 'bottom',
};

export function CloseSnackbar({ onClose }: { onClose?: VoidFunction }) {
	return (
		<IconButton onClick={onClose} sx={{ p: '0.25rem', ml: '1rem' }} data-testid="close-btn">
			<SolidIcon icon={SolidIconDefinitions.X_MARK_ICON} size={IconSizeEnum.XXSMALL} />
		</IconButton>
	);
}

export const defaultSnackbarConfig = {
	position: snackbarPosition,
	action: <CloseSnackbar />,
};
