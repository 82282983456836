import { Box, useMediaQuery, useTheme } from '@mui/material';
import {
	AssignmentViewerPopup,
	ILAssignmentViewerPopupArgs,
	ILBody2,
	IlCardAssignmentProps,
	ILH5,
	IlStudentHeader,
	IlStudentHeaderProps,
	PlannerDayCourseList,
	useEnrollmentContext,
	usePlannerContext,
} from '@edgenuity/cns-ui';
import React from 'react';
import { formatDueDateString } from '@edgenuity/cns-ui/shared/helpers/date-helper';
import ShowManageOverdueButton from '../manage-overdue-button';
import { usePlannerRules } from '../../hooks/planner.hook';
import StudentEventList from '../student-event-list';

export default function PlannerViewDay({ className }: { className?: string }) {
	usePlannerRules();

	const { dayAssignmentList } = useEnrollmentContext();
	const { selectedActivities } = usePlannerContext();
	const showEvents = React.useMemo(
		() => selectedActivities.includes('Events'),
		[selectedActivities]
	);

	const theme = useTheme();
	const hiddenDownMd = useMediaQuery(theme.breakpoints.down('md'));

	const [selectedAssignment, setSelectedAssignment] = React.useState<
		| {
				student: IlStudentHeaderProps;
				assignment: IlCardAssignmentProps;
		  }
		| undefined
	>();

	const hasStudents = dayAssignmentList?.length > 0;

	return (
		<Box
			className={'il-planner-view-day ' + className}
			sx={{
				paddingTop: '1.5rem',
				maxWidth: '100%',
				'& *': {
					boxSizing: 'content-box',
				},
			}}
		>
			{hasStudents ? (
				[...dayAssignmentList]
					.sort((studentAssignments1, studentAssignments2) => {
						const name1: string = studentAssignments1.studentHeaderArgs.name?.toString() || '';
						const name2: string = studentAssignments2.studentHeaderArgs.name?.toString() || '';

						return name1.localeCompare(name2);
					})
					.map((dayStudentArgs, idx) => {
						return (
							<Box sx={{ pb: '1.5rem' }} key={`${dayStudentArgs?.studentHeaderArgs?.name}-${idx}`}>
								<IlStudentHeader
									{...{
										sx: {
											maxWidth: { xs: '90%', md: '96%' },
											width: '45rem',
											m: '0 1rem 1rem',
											'& .MuiAvatar-root': {
												width: { xs: '2.5rem', md: '3rem' },
												height: { xs: '2.5rem', md: '3rem' },
											},
										},
										...dayStudentArgs.studentHeaderArgs,
										...(hiddenDownMd && { overdueText: undefined }),
									}}
								>
									<ShowManageOverdueButton
										dayStudentArgs={{
											...dayStudentArgs,
											studentHeaderArgs: {
												...dayStudentArgs.studentHeaderArgs,
												...(hiddenDownMd && { overdueText: undefined }),
											},
										}}
										sx={{
											'& .MuiAvatar-root': {
												width: { xs: '2.5rem', md: '3rem' },
												height: { xs: '2.5rem', md: '3rem' },
											},
											'& .il-student-header': {
												'& .MuiButton-root': {
													fontSize: { xs: '80%', md: '100%' },
												},
											},
										}}
									/>
								</IlStudentHeader>
								<Box
									sx={{
										'& .MuiListItem-root': {
											flexDirection: 'column',
											alignItems: 'stretch',
										},
									}}
								>
									{showEvents && <StudentEventList student={dayStudentArgs.studentHeaderArgs} />}
									<PlannerDayCourseList
										{...{ ...dayStudentArgs.courseListArgs }}
										selectAssignment={(assignment: IlCardAssignmentProps) => {
											setSelectedAssignment({
												student: dayStudentArgs.studentHeaderArgs,
												assignment,
											});
										}}
									/>
								</Box>
							</Box>
						);
					})
			) : (
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<ILBody2 sx={{ color: 'text.disabled', display: 'flex', textAlign: 'center' }}>
						Create a student to get started.
					</ILBody2>
				</Box>
			)}
			{selectedAssignment && (
				<AssignmentViewerPopup
					open={!!selectedAssignment}
					onClose={() => setSelectedAssignment(undefined)}
					dueText={formatDueDateString(
						selectedAssignment.assignment.dueDate,
						selectedAssignment.assignment.dateToday
					)}
					courseSubject={selectedAssignment.assignment.subject}
					avatar={selectedAssignment.student.avatar}
					studentName={selectedAssignment.student.name as string}
					menuComponent={ILAssignmentViewerPopupArgs.menuComponent}
				>
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<ILH5 sx={{ m: 10 }}>Assignment preview is under construction.</ILH5>
					</Box>
				</AssignmentViewerPopup>
			)}
		</Box>
	);
}
