import React from 'react';
import {
	AuthService,
	BrandEnum,
	BrandProvider,
	ConfigProvider,
	getBrandState,
	withBrand,
	withConfig,
	PlannerProvider,
	ILEnrollmentProvider,
} from '@edgenuity/cns-ui';

import { Planner } from '../planner';
import './harness.css';

function RouteWrapper({ Component }: { Component: React.ElementType }) {
	const authService: AuthService = new AuthService();

	return (
		<BrandProvider state={getBrandState(authService.brand ?? BrandEnum.MONARCH)}>
			<ConfigProvider>
				<PlannerProvider>
					<ILEnrollmentProvider>
						<Component />
					</ILEnrollmentProvider>
				</PlannerProvider>
			</ConfigProvider>
		</BrandProvider>
	);
}

export default function Harness() {
	return (
		<div className="harness">
			<RouteWrapper Component={withConfig(withBrand(Planner))} />
		</div>
	);
}
