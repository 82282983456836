import React from 'react';
import {
	usePlannerContext,
	ResponsiveRescheduleModule,
	OtkEnrollmentService,
	ConfigContext,
	ConfigState,
	StudentSummary,
	CustomIconTypeEnum,
	BrandContext,
	BrandState,
	BrandEnum,
	OtkUserInterface,
} from '@edgenuity/cns-ui';
import { CourseAttributeType } from '@edgenuity/cns-ui/components/il-active-course-list/il-active-course-list.props';
import { CourseDataInterface } from '@edgenuity/cns-ui/components/reschedule-activity/reschedule-activity.props';
import { useFetchAssignmentsPerCourse } from '../../hooks/fetch-assignments-per-course.hook';
import { courseDateRange } from '../../shared/utils/planner.utils';

const WeekdayToNumber: { [k: string]: number } = {
	SUNDAY: 0,
	MONDAY: 1,
	TUESDAY: 2,
	WEDNESDAY: 3,
	THURSDAY: 4,
	FRIDAY: 5,
	SATURDAY: 6,
};

export function mapWeekdayToNumber(weekDay: string | number): number {
	return WeekdayToNumber[`${weekDay}`.toUpperCase()] ?? Number(weekDay);
}

export function findSubjectIcon(brand: BrandEnum, courseSubject: string) {
	return Object.keys(CustomIconTypeEnum).find(
		(i) =>
			i === `${brand}_${courseSubject}` ||
			i.includes(courseSubject) ||
			courseSubject.includes(i.split('_')[1])
	) as CustomIconTypeEnum;
}

export function splitWords(phrase: string, spl = '_', join = ' ') {
	return phrase.toLowerCase().split(spl).join(join);
}

export default function RescheduleModuleImplementation() {
	const { toggleReschedule, students, openReschedule } = usePlannerContext();

	const [schoolGradingSummary, setSchoolGradingSummary] = React.useState<StudentSummary[]>([]);
	const [courses, setCourses] = React.useState<CourseAttributeType[] | undefined>();
	const [selectedCourse, setSelectedCourse] = React.useState<CourseAttributeType | undefined>();
	const averageAssignment = useFetchAssignmentsPerCourse(selectedCourse);
	const [selectedStudent, setSelectedStudent] = React.useState<OtkUserInterface | undefined>();
	const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);

	const brandState = React.useContext(BrandContext) as BrandState;
	const configState = React.useContext(ConfigContext) as ConfigState;

	const enrollmentService = React.useMemo(
		() => new OtkEnrollmentService(configState.service),
		[configState.service]
	);

	const fetchCourses = React.useCallback(
		async (studentId: string) => {
			setSelectedCourse(undefined);
			setShowSuccessMessage(false);
			const coursesScheduleData: CourseAttributeType[] = [];
			for (const courseSummary of schoolGradingSummary
				.filter((sgs) => sgs.studentId === studentId)
				.map((sgs) => sgs.courses)
				.flat()) {
				try {
					const courseSchedule = await enrollmentService.getCourseScheduleData(
						studentId,
						courseSummary.courseId
					);
					const subjectIcon = findSubjectIcon(brandState.brand, courseSummary.subject);

					coursesScheduleData.push({
						key: courseSummary.courseId,
						customIconType: subjectIcon,
						courseName: splitWords(courseSummary.subject),
						assignmentName: courseSummary.courseName,
						startDate: new Date(courseSchedule?.startDate ?? new Date()),
						endDate: new Date(courseSchedule?.endDate ?? new Date()),
						weekdaySelections: courseSchedule?.schoolDays.map((sd) => mapWeekdayToNumber(sd)) ?? [],
					});
				} catch (e) {
					console.error((e as Error).message);
				}
			}
			setCourses(coursesScheduleData);
		},
		[brandState?.brand, enrollmentService, schoolGradingSummary]
	);

	React.useEffect(() => {
		async function fetchGradingSummary() {
			const gradingSummary = await enrollmentService.getGradingSummaryBySchool();
			setSchoolGradingSummary([gradingSummary].flat());
		}
		fetchGradingSummary();
	}, [enrollmentService]);

	async function onSubmit(rescheduleData: CourseDataInterface) {
		await enrollmentService.updateCourseScheduleData(
			selectedStudent!.guid,
			rescheduleData.courseId!,
			{
				courseId: rescheduleData.courseId!,
				courseTitle: selectedCourse?.courseName,
				endDate: rescheduleData.endDate,
				schoolDays: rescheduleData.schoolDays,
				startDate: rescheduleData.startDate,
				studentId: selectedStudent!.guid,
			}
		);
		setSelectedCourse(undefined);
		setShowSuccessMessage(true);
		await fetchCourses(selectedStudent!.guid);
	}

	const onlyStudents = React.useMemo(
		() => students.filter((student) => !student.guid.includes('all')),
		[students]
	);

	const onSelectStudent = React.useCallback(
		async (st: OtkUserInterface) => {
			setSelectedStudent(st);
			setShowSuccessMessage(false);
			setSelectedCourse(undefined);
			setCourses(undefined);
			await fetchCourses(st.studentId ?? st.guid);
		},
		[fetchCourses]
	);

	React.useEffect(() => {
		!selectedStudent && onlyStudents[0] && onSelectStudent(onlyStudents[0]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onlyStudents]);

	return (
		<ResponsiveRescheduleModule
			open={openReschedule}
			onClose={() => {
				openReschedule && toggleReschedule();
			}}
			students={onlyStudents}
			selectedStudent={selectedStudent}
			onSelectStudent={onSelectStudent}
			courses={courses}
			selectedCourse={selectedCourse}
			onSelectCourse={(course) => {
				setShowSuccessMessage(false);
				setSelectedCourse(course);
			}}
			averageAssignmentsPerDay={averageAssignment}
			onSubmit={onSubmit}
			defaultFormValues={{
				courseId: selectedCourse?.key,
				schoolDays: selectedCourse?.weekdaySelections,
				...courseDateRange(selectedCourse),
			}}
			sx={{
				'&.il-reschedule-module': {
					minHeight: '60vh',
				},
			}}
			showSuccessMessage={showSuccessMessage}
		/>
	);
}
