import React from 'react';
import {
	IlStudentHeaderProps,
	ILEventCard,
	ILCalendarContext,
	useEnrollmentContext,
	EventData,
	IconEnum,
	IlEventDetailModal,
	usePlannerContext,
	AddEventFormPopup,
	allStudentUser,
	ConfigContext,
	ConfigState,
	OtkEnrollmentService,
	NotificationContext,
	EventsTimeoffValuesInterface,
	IlHolidayEventCardArgs,
} from '@edgenuity/cns-ui';
import { Box } from '@mui/material';
import { endOfDay, format, startOfDay } from 'date-fns';
import { defaultSnackbarConfig } from '../snackbar-config';
import { parseDate } from '../../shared/utils/planner.utils';

export type StudentEventListProps = {
	student: IlStudentHeaderProps;
};

export const isHoliday = (event?: EventData) =>
	event?.eventId === '00000000-0000-0000-0000-000000000000';

export function getHolidayProps(event?: EventData) {
	if (event && isHoliday(event)) {
		const iconMap: { [k: string]: IconEnum } = {
			PARTY_HORN: IconEnum.PARTY_HORN_SOLID,
			STAR: IconEnum.STAR,
			SNOWFLAKE: IconEnum.SNOWFLAKE_SOLID,
			SUN: IconEnum.SUN_SOLID,
		};
		const icon = iconMap[event.eventIcon] ?? IconEnum.STAR;
		return {
			color: IlHolidayEventCardArgs.color,
			leftIcon: icon,
			eventIcon: icon,
			key: `${event.eventId}-${event.title}`,
			showDelete: false,
			showEdit: false,
		};
	}
	return {};
}

function StudentEventList({ student }: StudentEventListProps) {
	const ctx = React.useContext(ILCalendarContext);
	const configState = React.useContext(ConfigContext) as ConfigState;
	const { showNotification } = React.useContext(NotificationContext);

	const { students } = usePlannerContext();
	const { studentsEvents } = useEnrollmentContext();

	const [selectedEvent, setSelectedEvent] = React.useState<EventData | undefined>(undefined);
	const [showEdit, setShowEdit] = React.useState(false);

	const enrollmentService = React.useMemo(
		() => new OtkEnrollmentService(configState.service),
		[configState.service]
	);

	const events = React.useMemo(
		() =>
			student.studentId && studentsEvents && studentsEvents[student.studentId]
				? studentsEvents[student.studentId].filter((studentEvent) => {
						const startDate = parseDate(studentEvent.assignedDate, isHoliday(studentEvent));
						const endDate = parseDate(studentEvent.completedDate, isHoliday(studentEvent));
						return (
							startOfDay(startDate) <= endOfDay(ctx.selectedDate) &&
							startOfDay(ctx.selectedDate) <= endOfDay(endDate)
						);
				  })
				: [],
		[ctx.selectedDate, student.studentId, studentsEvents]
	);

	function handleClose() {
		setSelectedEvent(undefined);
		setShowEdit(false);
	}

	async function onDelete(evt: EventData) {
		try {
			await enrollmentService.deleteEvent(evt.eventId);
			showNotification({
				...defaultSnackbarConfig,
				message: 'Event successfully deleted.',
				key: 'event-deleted',
				severity: 'success',
			});
			handleClose();
		} catch {
			showNotification({
				...defaultSnackbarConfig,
				message: 'An error has occurred.',
				key: 'delete-event-error',
				severity: 'error',
			});
		}
	}

	async function updateEvent(evt: EventsTimeoffValuesInterface) {
		const selectedStudents = !!evt.students.find((s) => `${s}`.includes('all'))
			? students.map((st) => st.studentId ?? st.guid)
			: evt.students;
		try {
			for (const student of selectedStudents) {
				await enrollmentService.updateEvent({
					assignedDate: format(evt.startDate, 'yyyy-MM-dd'),
					completedDate: format(evt.endDate, 'yyyy-MM-dd'),
					eventIcon: evt.eventIcon,
					scheduleWork: evt.courseWork === 'keep',
					studentId: `${student}`,
					title: evt.title,
					note: evt.notes,
					eventId: selectedEvent?.eventId!,
				});
			}
			showNotification({
				...defaultSnackbarConfig,
				message: 'Event successfully updated.',
				key: 'event-updated',
				severity: 'success',
			});
			handleClose();
		} catch {
			showNotification({
				...defaultSnackbarConfig,
				message: 'An error has occurred.',
				key: 'update-event-error',
				severity: 'error',
			});
		}
	}

	return (
		<>
			<Box sx={{ px: '1rem' }}>
				{student && (
					<>
						{events?.map((event: EventData) => (
							<ILEventCard
								key={event.eventId}
								title={event.title}
								description={event.title}
								leftIcon={IconEnum[event.eventIcon as IconEnum] || IconEnum.UMBRELLA_BEACH}
								onClick={() => {
									setSelectedEvent(event);
								}}
								sx={{
									mb: '0.25rem',
									height: { xs: '1rem', sm: '1rem', md: '1.5rem', lg: '2rem', xl: '2rem' },
									'& .MuiTypography-root': {
										fontWeight: 600,
										lineHeight: { xs: '1rem', sm: '1rem', md: '1rem', lg: '1.5rem', xl: '1.5rem' },
										fontSize: {
											xs: '0.75rem',
											sm: '0.75rem',
											md: '0.875rem',
											lg: '0.875rem',
											xl: '0.875rem',
										},
									},
								}}
								{...getHolidayProps(event)}
							/>
						))}
					</>
				)}
			</Box>

			<IlEventDetailModal
				open={!!selectedEvent && !showEdit}
				onClose={handleClose}
				event={{
					eventId: '',
					title: '',
					scheduleWork: false,
					studentId: '',
					assignedDate: new Date(),
					completedDate: new Date(),
					...selectedEvent,
					eventIcon: IconEnum[selectedEvent?.eventIcon as IconEnum] || IconEnum.UMBRELLA_BEACH,
					...getHolidayProps(selectedEvent),
				}}
				students={students.filter((st) => st.guid === selectedEvent?.studentId)}
				onEdit={() => {
					setShowEdit(true);
				}}
				onDelete={onDelete}
				{...getHolidayProps(selectedEvent)}
			/>

			<AddEventFormPopup
				isEdit
				studentList={[allStudentUser, ...students]}
				open={showEdit}
				onClose={handleClose}
				onSubmit={(evt) => updateEvent(evt)}
				dateStartYear={new Date(selectedEvent?.assignedDate ?? new Date())}
				defaultFormValues={{
					eventIcon: IconEnum[selectedEvent?.eventIcon as IconEnum] || IconEnum.UMBRELLA_BEACH,
					notes: selectedEvent?.note,
					title: selectedEvent?.title,
					students: students
						.filter((st) => st.guid === selectedEvent?.studentId)
						.map((st) => st.guid),
					startDate: new Date(selectedEvent?.assignedDate!),
					endDate: new Date(selectedEvent?.completedDate!),
					courseWork: 'keep',
				}}
			/>
		</>
	);
}

export default StudentEventList;
