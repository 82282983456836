import {
	AssignmentStateEnum,
	ConfigContext,
	ConfigState,
	IlCardAssignmentProps,
	OtkEnrollmentService,
	ScheduleData,
	useEnrollmentContext,
	usePlannerContext,
} from '@edgenuity/cns-ui';
import { ButtonManageOverduePopup } from '@edgenuity/cns-ui/components/il-manage-student-overdue/il-manage-student-overdue.common';
import React from 'react';
import { CourseAssignmentType } from '@edgenuity/cns-ui/components/il-manage-student-overdue/il-manage-student-overdue.props';
import { PlannerViewDayTeacherStudentsProps } from '../planner-view-day/planner-view-day-teacher-students.props';
import StyleProps from '@edgenuity/cns-ui/shared/types/style-props.type';
import { CourseDataInterface } from '@edgenuity/cns-ui/components/reschedule-activity/reschedule-activity.props';
import {
	useFetchAssignmentsPerCourse,
	CourseQuery,
} from '../../hooks/fetch-assignments-per-course.hook';
import { courseDateRange } from '../../shared/utils/planner.utils';
import { mapWeekdayToNumber } from '../reschedule-module';

export default function ShowManageOverdueButton({
	dayStudentArgs,
	sx,
}: {
	dayStudentArgs: PlannerViewDayTeacherStudentsProps;
	sx?: StyleProps;
}) {
	const [selectedCourse, setSelectedCourse] = React.useState<CourseQuery | undefined>();
	const averageAssignment = useFetchAssignmentsPerCourse(selectedCourse);
	const { updateCourseScheduleData } = useEnrollmentContext();

	const configState = React.useContext(ConfigContext) as ConfigState;
	const enrollmentService = React.useMemo(
		() => new OtkEnrollmentService(configState.service),
		[configState.service]
	);

	const { students } = usePlannerContext();
	const selectedStudent = students.find(
		(student) => dayStudentArgs.studentHeaderArgs?.name === student.firstName
	);

	async function onSubmit(rescheduleData: CourseDataInterface) {
		await updateCourseScheduleData(selectedStudent!, rescheduleData, selectedCourse?.courseName!);
	}

	async function fetchCourseData(courseId: string) {
		let courseSchedule: ScheduleData | undefined;
		if (dayStudentArgs.studentHeaderArgs.studentId) {
			courseSchedule = await enrollmentService.getCourseScheduleData(
				dayStudentArgs.studentHeaderArgs.studentId,
				courseId
			);
		}
		setSelectedCourse(
			(prev) =>
				({
					...prev,
					key: courseSchedule?.courseId,
					startDate: new Date(courseSchedule?.startDate ?? new Date()),
					endDate: new Date(courseSchedule?.endDate ?? new Date()),
					schoolDays: courseSchedule?.schoolDays?.map((sd) => mapWeekdayToNumber(sd)) ?? [],
				} as CourseQuery)
		);
	}

	return (
		<>
			{dayStudentArgs.studentHeaderArgs?.overdueNumber &&
				dayStudentArgs.studentHeaderArgs?.overdueNumber > 0 && (
					<ButtonManageOverduePopup
						sx={{
							...sx,
							'& .assignment-list': {
								minWidth: { sx: '100%', md: '19rem', lg: '20rem' },
							},
							'& .reschedule-form': {
								minHeight: '55vh',
							},
						}}
						onSubmit={onSubmit}
						studentProps={{
							...dayStudentArgs.studentHeaderArgs,
							assignmentsNumber: dayStudentArgs.studentHeaderArgs.overdueNumber,
							assignmentsText: 'Overdue',
							overdueText: undefined,
						}}
						title="Manage Overdue Work"
						averageAssignmentsPerDay={averageAssignment}
						onReschedule={(course) => {
							fetchCourseData(course.assignmentProps[0].courseId! || course.courseId);
						}}
						defaultFormValues={{
							...selectedCourse,
							courseId: selectedCourse?.key,
							...courseDateRange(selectedCourse),
						}}
						coursesAssignment={dayStudentArgs.courseListArgs.assignmentGroupArgs
							.flat()
							.map(
								(g) =>
									({
										courseId: (g.assignmentProps[0] as IlCardAssignmentProps).courseId,
										courseName: g.assignmentProps[0].courseTitle,
										assignmentProps: g.assignmentProps
											.filter((a) => a.assignmentState === AssignmentStateEnum.OVERDUE)
											.map((a) => ({ ...a, sx: undefined })),
									} as CourseAssignmentType)
							)
							.flat()
							.filter((c) => c.assignmentProps.length > 0)}
					/>
				)}
		</>
	);
}
