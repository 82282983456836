import React from 'react';
import { Box, IconButton } from '@mui/material';
import {
	ILCalendarContext,
	ILCalendarDatePickerWithContext,
	ILCalendarSelector,
	ILWeekdaySelectorWithContext,
	RegularIcon,
	SolidIcon,
	TodayButton,
	TodayButtonArgs,
} from '@edgenuity/cns-ui';
import { SolidIconDefinitions } from '@edgenuity/cns-ui/components/il-icon/solid-icon-definitions';
import { ILCalendarNavigator } from '@edgenuity/cns-ui/components/il-calendar-navigator/il-calendar-navigator';
import { PlannerToolbarProps } from '../planner-toolbar/planner-toolbar.props';

export default function PlannerCalendarControl({
	headerItems,
	className,
	mobileStartHeader = (
		<IconButton>
			<SolidIcon
				sx={{
					color: 'primary.dark',
				}}
				icon={SolidIconDefinitions.MENU}
			/>
		</IconButton>
	),
	...props
}: PlannerToolbarProps) {
	const ctx = React.useContext(ILCalendarContext);
	function setToday() {
		ctx?.changeSelectedDate(new Date());
	}
	const mobileEndHeader = props.mobileEndHeader || (
		<IconButton onClick={setToday}>
			<RegularIcon sx={{ color: 'text.disabled' }} />
		</IconButton>
	);
	return (
		<Box className={'il-planner-calendar-control ' + className}>
			<Box
				sx={{
					display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' },
					justifyContent: 'space-between',
					flexDirection: 'row',
					alignItems: 'center',
					pb: 1.25,
					pt: 1,
					px: 1,
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						gap: 3,
						width: '40%',
						'& .il-calendar-navigator': { mt: 0, alignItems: 'center' },
					}}
				>
					<Box
						sx={{
							alignItems: 'center',
							display: 'flex',
						}}
					>
						<ILCalendarDatePickerWithContext />
					</Box>
					<Box
						sx={{
							alignItems: 'center',
							display: 'flex',
							height: '2rem',
						}}
					>
						<ILCalendarNavigator />
					</Box>
					<Box sx={{ minWidth: '5.5rem', alignItems: 'center', display: 'flex' }}>
						<TodayButton
							sx={{
								...TodayButtonArgs.sx,
								borderColor: 'action.disabled',
								px: 0,
								width: '100%',
								borderWidth: '0.0625rem',
								p: 1.5,
								fontSize: '1rem',
								'& .MuiButton-startIcon svg': {
									width: '1.625rem',
									height: '1.625rem',
								},
							}}
							onClick={setToday}
						/>
					</Box>
				</Box>

				<ILCalendarSelector
					sx={{
						height: '2rem',
						width: '60%',
					}}
				/>
				<Box
					sx={{
						alignItems: 'center',
						display: 'flex',
					}}
				>
					{headerItems}
				</Box>
			</Box>
			<Box
				sx={{
					display: {
						xs: 'flex',
						sm: 'flex',
						md: 'flex',
						lg: 'none',
						xl: 'none',
					},
					alignItems: 'stretch',
					flexDirection: 'column',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						mb: '1rem',
						px: '0.25rem',
					}}
				>
					<Box>{mobileStartHeader}</Box>
					<Box>
						<ILCalendarDatePickerWithContext />
					</Box>
					<Box>{mobileEndHeader}</Box>
				</Box>
				<ILWeekdaySelectorWithContext sx={{ mb: '1rem' }} />
			</Box>
		</Box>
	);
}
