import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import {
	RescheduleButton,
	AddEventButton,
	ActivityFilterImplementation,
	StudentFilter,
	AddEventButtonArgs,
	RescheduleButtonArgs,
	FilterDrawerContext,
	usePlannerContext,
	CalendarActionsDrawerContext,
} from '@edgenuity/cns-ui';
import { alpha } from '@mui/material/styles';
import PlannerActions from '../planner-actions';

export default function PlannerFiltersToolbar({ className }: { className?: string }) {
	const theme = useTheme();
	const {
		toggleAddEvent,
		toggleReschedule,
		activities,
		students,
		selectActivities,
		selectStudent,
	} = usePlannerContext();

	const dropdownSx = {
		'& .MuiSelect-select': {
			padding: '4px 10px',
			minHeight: 30,
			display: 'flex',
			alignItems: 'center',
		},
		'& *': { maxWidth: '20rem' },
		'& .MuiInputBase-root': { minWidth: 0 },
		minWidth: '11rem',
		maxWidth: '20rem',
	};

	const buttonSx = {
		p: '0.7rem',
		fontSize: '1rem',
		'& .MuiButton-startIcon svg': {
			width: '1.625rem',
			height: '1.625rem',
			color: alpha(theme.palette.text.primary, 0.8),
		},
	};

	const hasActivities = activities?.length > 0;
	const hasStudents = students?.length > 0;
	const hasActivitiesAndStudents = hasActivities && hasStudents;

	return (
		<Box
			className={'il-planner-filter-toolbar ' + className}
			sx={{
				mx: 0,
				display: 'flex',
				flexDirection: 'column',
				backgroundColor: 'background.default',
				color: 'text.primary',
				px: 1,
			}}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					flexDirection: 'row',
					gap: 2,
				}}
			>
				<Box
					sx={{
						display: { xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none' },
						alignItems: 'center',
						gap: 1,
					}}
				>
					{hasActivitiesAndStudents && (
						<FilterDrawerContext
							sx={{
								'& button': {
									fontSize: '1rem',
								},
							}}
						/>
					)}
				</Box>
				<Box
					sx={{
						display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' },
						alignItems: 'center',
						gap: 1,
					}}
				>
					<Typography sx={{ fontSize: 13, color: 'text.secondary' }}>Filter by</Typography>
					<Box sx={{ maxWidth: '15rem' }}>
						{hasStudents && (
							<StudentFilter sx={dropdownSx} studentsArr={students} onChange={selectStudent} />
						)}
					</Box>
					<Box sx={{ maxWidth: '15rem' }}>
						{hasActivities && (
							<ActivityFilterImplementation
								sx={dropdownSx}
								activities={activities}
								onChange={selectActivities}
							/>
						)}
					</Box>
				</Box>
				<Box
					sx={{
						display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' },
						alignItems: 'center',
						gap: 0.5,
					}}
				>
					<AddEventButton sx={{ ...AddEventButtonArgs.sx, ...buttonSx }} onClick={toggleAddEvent} />
					<RescheduleButton
						sx={{ ...RescheduleButtonArgs.sx, ...buttonSx }}
						onClick={toggleReschedule}
					/>
				</Box>
				<Box
					sx={{
						display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' },
						alignItems: 'center',
						gap: 0.5,
					}}
				>
					<CalendarActionsDrawerContext
						showTimeoff={false}
						sx={{
							'& button': {
								fontSize: '1rem',
							},
						}}
					/>
				</Box>
			</Box>

			<PlannerActions />
		</Box>
	);
}
