import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import './index.css';
import Harness from './components/harness/harness';
import {
	IdentityRolesEnum,
	ILCalendarProvider,
	IlErrorBoundaryWithBrandAndNotifications,
	OtkUserInterface,
} from '@edgenuity/cns-ui';
import { UserDataProvider } from '@edgenuity/cns-ui/providers/user-data-provider/user-data.provider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<React.StrictMode>
		<IlErrorBoundaryWithBrandAndNotifications>
			<ILCalendarProvider>
				<UserDataProvider state={{ role: IdentityRolesEnum.TEACHER } as OtkUserInterface}>
					<Harness />
				</UserDataProvider>
			</ILCalendarProvider>
		</IlErrorBoundaryWithBrandAndNotifications>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
