import { ILCalendarContext, useEnrollmentContext, usePlannerContext } from '@edgenuity/cns-ui';
import React from 'react';
import {
	generateCountActivities,
	generateStudentAssignmentDayList,
	generateStudentAssignmentDaySelections,
	generateStudentList,
} from '../shared/utils/planner.utils';
import { addDays, subDays } from 'date-fns';

export function usePlannerRules() {
	const { students, selectedActivities, activities, selectedStudent } = usePlannerContext();
	const { updateDayAssignmentList, schoolSchedule, updateDateWindow } = useEnrollmentContext();
	const calendarCtx = React.useContext(ILCalendarContext);

	React.useEffect(() => {
		updateDayAssignmentList(
			generateStudentAssignmentDayList(
				{
					students: generateStudentList(students, selectedStudent),
					schoolSchedule: schoolSchedule,
				},
				generateStudentAssignmentDaySelections(calendarCtx.selectedDate, selectedActivities),
				calendarCtx?.selectedDate
			)
		);
		generateCountActivities(
			schoolSchedule,
			activities,
			calendarCtx.selectedDate,
			generateStudentList(students, selectedStudent)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [students, schoolSchedule, selectedActivities, selectedStudent, calendarCtx.selectedDate]);

	React.useEffect(() => {
		updateDateWindow({
			start: subDays(calendarCtx.selectedDate, 45),
			end: addDays(calendarCtx.selectedDate, 45),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [calendarCtx.selectedDate]);
}
