import React from 'react';
import { Box, AppBar } from '@mui/material';
import PlannerToolbar from '../planner-toolbar';
import {
	ILCalendarContext,
	UnderConstructionPageBanner,
	NoCoursesAssigned,
	IdentityRolesEnum,
	UserDataContext,
	usePlannerContext,
	useEnrollmentContext,
} from '@edgenuity/cns-ui';
import { PlannerProps } from './planner.props';
import PlannerViewDay from '../planner-view-day';
import { useResponsive } from '../../hooks/responsive.hook';

export function Planner({
	sx,
	plannerToolbarProps,
	className,
	children,
}: React.PropsWithChildren<PlannerProps>) {
	useResponsive();

	const calendarCtx = React.useContext(ILCalendarContext);
	const userCtx = React.useContext(UserDataContext);
	const notStudent = IdentityRolesEnum.STUDENT !== userCtx.role;

	const { fetchStudents, fetchActivities } = usePlannerContext();
	React.useEffect(() => {
		fetchStudents && fetchStudents();
		fetchActivities && fetchActivities();
	}, [fetchActivities, fetchStudents]);

	const { fetchSchoolSchedule } = useEnrollmentContext();
	React.useEffect(() => {
		fetchSchoolSchedule && fetchSchoolSchedule();
	}, [fetchSchoolSchedule]);

	let teacherCalendarElement: JSX.Element = (
		<Box sx={{ pt: '2rem' }}>
			<UnderConstructionPageBanner
				sx={{
					'& img': {
						width: '100% !important',
						maxWidth: 320,
					},
				}}
				titleSx={{
					p: '0.5rem',
					fontSize: { xs: 36, sm: 46, md: 46, lg: 46, xl: 46 },
				}}
				lightTitle={false}
				description={`The ${calendarCtx.calendarType?.toLowerCase()} view is currently under construction`}
				actionText={undefined}
			/>
		</Box>
	);

	switch (calendarCtx.calendarType) {
		case 'DAY': {
			teacherCalendarElement = <PlannerViewDay />;
			break;
		}
		// NOTE: Will add Week and Month elements in the future
		default: {
			// Do Nothing
			break;
		}
	}

	return (
		<Box
			className={'il-planner ' + className}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				minHeight: '100vh',
				backgroundColor: 'background.paper',
				...sx,
			}}
		>
			<AppBar sx={{ color: 'text.primary', boxShadow: 'none' }}>
				<PlannerToolbar {...plannerToolbarProps} />
			</AppBar>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					marginTop: {
						xs: '11rem',
						lg: '7rem',
					},
				}}
			>
				{notStudent ? (
					teacherCalendarElement
				) : (
					<NoCoursesAssigned
						sx={{ mt: 2 }}
						subtitle="No Courses Assigned"
						description="Your weekly assignments will appear here."
					/>
				)}
			</Box>

			{children}
		</Box>
	);
}
