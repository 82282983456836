import { ConfigContext, ConfigState, OtkEnrollmentService } from '@edgenuity/cns-ui';
import React from 'react';

export type CourseQuery = {
	startDate: Date;
	endDate: Date;
	key: string;
	courseName?: string;
	schoolDays?: number[];
};

export function useFetchAssignmentsPerCourse(course?: CourseQuery): number | undefined {
	const [averageAssignment, setAverageAssignment] = React.useState<number | undefined>();
	const configState = React.useContext(ConfigContext) as ConfigState;

	const enrollmentService = React.useMemo(
		() => new OtkEnrollmentService(configState.service),
		[configState.service]
	);

	React.useEffect(() => {
		async function fetchData() {
			setAverageAssignment(undefined);
			if (course) {
				const avg = await enrollmentService.getAssignmentSummaryData(
					course.key,
					course.startDate,
					course.endDate
				);
				setAverageAssignment(avg);
			}
		}

		fetchData();
	}, [course, enrollmentService]);

	return averageAssignment;
}
