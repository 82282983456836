import { ILCalendarContext } from '@edgenuity/cns-ui';
import React from 'react';
import { Theme, useMediaQuery } from '@mui/material';

export function useResponsive() {
	const isMobile = useMediaQuery<Theme>((theme) => theme?.breakpoints?.down('lg'));

	const calendarCtx = React.useContext(ILCalendarContext);

	React.useEffect(() => {
		isMobile && calendarCtx.changeCalendarType('DAY');
	}, [isMobile, calendarCtx]);
}
