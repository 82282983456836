import React from 'react';
import { Box, Divider } from '@mui/material';
import PlannerFiltersToolbar from '../planner-filters';
import { PlannerToolbarProps } from './planner-toolbar.props';
import PlannerCalendarControl from '../planner-calendar-control';
import { IdentityRolesEnum, UserDataContext } from '@edgenuity/cns-ui';

export default function PlannerToolbar({ sx, ...props }: PlannerToolbarProps) {
	const ctx = React.useContext(UserDataContext);
	const notStudent = IdentityRolesEnum.STUDENT !== ctx.role;
	return (
		<Box
			className={'il-planner-toolbar ' + props.className}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				backgroundColor: 'background.default',
				...sx,
			}}
		>
			<PlannerCalendarControl {...{ ...props, sx: { color: 'red' } }} />
			<Divider
				sx={{
					display: {
						xs: 'none',
						sm: 'none',
						md: 'none',
						lg: 'flex',
						xl: 'flex',
					},
				}}
			/>
			{notStudent && (
				<>
					<PlannerFiltersToolbar {...props} />
					<Divider />
				</>
			)}
		</Box>
	);
}
