import React from 'react';
import {
	usePlannerContext,
	allStudentUser,
	AddEventFormPopup,
	OtkEnrollmentService,
	ConfigContext,
	ConfigState,
	EventsTimeoffValuesInterface,
	EventData,
	NotificationContext,
} from '@edgenuity/cns-ui';
import RescheduleModuleImplementation from '../reschedule-module';
import { format } from 'date-fns';
import { defaultSnackbarConfig } from '../snackbar-config';

export default function PlannerActions() {
	const configState = React.useContext(ConfigContext) as ConfigState;
	const { showNotification } = React.useContext(NotificationContext);

	const { students, openAddEvent, toggleAddEvent } = usePlannerContext();

	const enrollmentService = React.useMemo(
		() => new OtkEnrollmentService(configState.service),
		[configState.service]
	);

	async function onAddEvent(evt: EventsTimeoffValuesInterface, isEvent: boolean) {
		const selectedStudents = !!evt.students.find((s) => `${s}`.includes('all'))
			? students.map((st) => st.studentId ?? st.guid)
			: evt.students;
		try {
			for (const student of selectedStudents) {
				await enrollmentService.createNewEvent({
					assignedDate: format(evt.startDate, 'yyyy-MM-dd'),
					completedDate: format(evt.endDate, 'yyyy-MM-dd'),
					eventIcon: evt.eventIcon,
					scheduleWork: isEvent,
					studentId: student,
					title: evt.title,
					note: evt.notes,
				} as EventData);
			}
			showNotification({
				...defaultSnackbarConfig,
				message: 'Event successfully added.',
				key: 'add-event',
				severity: 'success',
			});
			openAddEvent && toggleAddEvent();
		} catch {
			showNotification({
				...defaultSnackbarConfig,
				message: 'An error has occurred.',
				key: 'add-event-error',
				severity: 'error',
			});
		}
	}

	return (
		<>
			<RescheduleModuleImplementation />
			<AddEventFormPopup
				onClose={toggleAddEvent}
				open={openAddEvent}
				studentList={[allStudentUser, ...students]}
				onSubmit={(evt) => onAddEvent(evt, evt.courseWork === 'keep')}
			/>
		</>
	);
}
