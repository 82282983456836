import {
	ActivityOptionInterface,
	AssignmentStateEnum,
	AssignmentTypeEnum,
	IlCardAssignmentProps,
	IlCardGroupAssignmentProps,
	OtkUserInterface,
	SubjectEnum,
	supplyNow,
} from '@edgenuity/cns-ui';
import {
	ActivityData,
	SchoolScheduleData,
	StudentActivitiesEvents,
} from '@edgenuity/cns-ui/shared/interfaces/enrollment/school-schedule.interface';
import { PlannerViewDayTeacherStudentsProps } from '../../components/planner-view-day/planner-view-day-teacher-students.props';
import { endOfDay, isPast, isSameDay, isToday, parseISO, startOfDay } from 'date-fns';
import { CourseQuery } from '../../hooks/fetch-assignments-per-course.hook';
import { isHoliday } from '../../components/student-event-list';

type StudentAssignmentDayListDataToMerge = {
	students: OtkUserInterface[];
	schoolSchedule: SchoolScheduleData;
};
type StudentAssignmentDayListSelections = {
	selectedDate: Date;
	selectedAssignmentTypes: AssignmentTypeEnum[];
	selectOverdue: boolean;
	selectEvents: boolean;
};

function generateStudentAssignmentDaySelections(
	selectedDate: Date,
	selectedActivities: string[]
): StudentAssignmentDayListSelections {
	const selections: StudentAssignmentDayListSelections = {
		selectedDate: selectedDate,
		selectedAssignmentTypes: [],
		selectOverdue: false,
		selectEvents: false,
	};

	selectedActivities.forEach((activity) => {
		switch (activity.toLowerCase()) {
			case 'overdue': {
				selections.selectOverdue = true;
				break;
			}
			case 'events': {
				selections.selectEvents = true;
				break;
			}
			case 'lessons': {
				selections.selectedAssignmentTypes.push(AssignmentTypeEnum.LESSON);
				break;
			}
			case 'quizzes': {
				selections.selectedAssignmentTypes.push(AssignmentTypeEnum.QUIZ);
				break;
			}
			case 'tests': {
				selections.selectedAssignmentTypes.push(AssignmentTypeEnum.TEST);
				break;
			}
			case 'resources': {
				selections.selectedAssignmentTypes.push(AssignmentTypeEnum.RESOURCE);
				break;
			}
			case 'projects': {
				selections.selectedAssignmentTypes.push(AssignmentTypeEnum.PROJECT);
				break;
			}
			default: {
				// Do Nothing
			}
		}
	});

	return selections;
}

function generateStudentList(
	students: OtkUserInterface[],
	selectedStudent: string | string[]
): OtkUserInterface[] {
	if (
		(!Array.isArray(selectedStudent) && selectedStudent === 'allStudentUsers') ||
		(Array.isArray(selectedStudent) && selectedStudent[0] === 'allStudentUsers')
	) {
		return [...students];
	}

	return Array.isArray(selectedStudent)
		? students.filter((student) => selectedStudent.includes(student.guid))
		: students.filter((student) => student.guid === selectedStudent);
}

function generateStudentAssignmentDayList(
	dataToMerge: StudentAssignmentDayListDataToMerge,
	selections: StudentAssignmentDayListSelections,
	date: Date | undefined = supplyNow()
): PlannerViewDayTeacherStudentsProps[] {
	const assignmentList: PlannerViewDayTeacherStudentsProps[] = [];
	const today = supplyNow();

	function overdueFilter(activity: ActivityData) {
		return (
			isPast(parseISO(activity.assignedDate)) &&
			(selections.selectedAssignmentTypes.includes(mapStringToAssignmentTypeEnum(activity.type)) ||
				selections.selectOverdue)
		);
	}

	dataToMerge.students.forEach((student) => {
		const studentSchedule = dataToMerge.schoolSchedule[student.guid.toString()];
		const studentActivities = studentSchedule?.activities;
		const studentOverdues = studentSchedule?.overdueActivities;

		// NOTE: Assuming that no activities = noEnrollments. If this assumption proves incorrect then we will need
		//       to update the API to return a flag for this.
		const noEnrollments = !studentActivities?.length && !studentOverdues?.length;

		let assignmentNumber = 0;
		let overdueNumber = 0;

		// Group Assignments By course
		let studentCourses: { [key: string]: IlCardAssignmentProps[] } = {};

		if (!noEnrollments) {
			const filteredActivities = [...studentActivities].filter(
				(activity) =>
					isSameDay(parseISO(activity.assignedDate), selections.selectedDate) &&
					selections.selectedAssignmentTypes.includes(mapStringToAssignmentTypeEnum(activity.type))
			);

			const filteredOverdues = isToday(date) ? [...studentOverdues].filter(overdueFilter) : [];

			// Increment counts
			overdueNumber = filteredOverdues.length;
			assignmentNumber = filteredActivities.length + filteredOverdues.length;

			[...filteredActivities, ...filteredOverdues].forEach((selectedActivity) => {
				// Add activity to course
				const assignmentCardProps = activityToCardAssignmentProps(selectedActivity);
				studentCourses[selectedActivity.courseId] = studentCourses[selectedActivity.courseId]
					? [...studentCourses[selectedActivity.courseId], assignmentCardProps]
					: [assignmentCardProps];
			});
		}

		// Create the Assignment Group Props
		const assignmentGroupArgs: IlCardGroupAssignmentProps[] = [];
		for (let studentCoursesKey in studentCourses) {
			assignmentGroupArgs.push({
				assignmentProps: studentCourses[studentCoursesKey],
				dateSelected: selections.selectedDate,
				dateToday: today,
			} as IlCardGroupAssignmentProps);
		}

		assignmentList.push({
			studentHeaderArgs: {
				studentId: student.guid.toString(),
				name: student.firstName,
				avatar: student.avatar,
				assignmentsNumber: assignmentNumber,
				assignmentsText: assignmentNumber !== 1 ? 'Assignments' : 'Assignment',
				overdueText: overdueNumber ? 'Overdue' : undefined,
				overdueNumber: overdueNumber || undefined,
			},
			courseListArgs: {
				assignmentGroupArgs: assignmentGroupArgs,
				noEnrollments: noEnrollments,
			},
		} as PlannerViewDayTeacherStudentsProps);
	});

	return assignmentList;
}

function activityToCardAssignmentProps(activity: ActivityData): IlCardAssignmentProps {
	return {
		courseId: activity.courseId,
		assignmentId: activity.activityId,
		assignmentState: calculateAssignmentState(activity),
		assignmentTitle: activity.title,
		assignmentType: mapStringToAssignmentTypeEnum(activity.type),
		courseTitle: activity.courseTitle,
		subject: mapStringToSubjectEnum(activity.subject),
		dateToday: supplyNow(),
		dueDate: parseISO(activity.assignedDate),
		sequence: activity.sequence,
		unitTitle: activity.unitTitle,
		score: activity.score,
		scoreTotal: activity.scoreTotal,
		meetsThreshold: activity.meetsThreshold,
	} as IlCardAssignmentProps;
}

function mapStringToAssignmentTypeEnum(assignmentTypeString: string): AssignmentTypeEnum {
	let assignmentTypeReturn: AssignmentTypeEnum;

	switch (assignmentTypeString.toUpperCase()) {
		case AssignmentTypeEnum.LESSON.toUpperCase(): {
			assignmentTypeReturn = AssignmentTypeEnum.LESSON;
			break;
		}
		case AssignmentTypeEnum.QUIZ.toUpperCase(): {
			assignmentTypeReturn = AssignmentTypeEnum.QUIZ;
			break;
		}
		case AssignmentTypeEnum.TEST.toUpperCase(): {
			assignmentTypeReturn = AssignmentTypeEnum.TEST;
			break;
		}
		case AssignmentTypeEnum.PROJECT.toUpperCase(): {
			assignmentTypeReturn = AssignmentTypeEnum.PROJECT;
			break;
		}
		case AssignmentTypeEnum.RESOURCE.toUpperCase(): {
			assignmentTypeReturn = AssignmentTypeEnum.RESOURCE;
			break;
		}
		default: {
			console.warn('Unknown assignment type: ' + assignmentTypeString + ". Defaulting to 'LESSON'");
			assignmentTypeReturn = AssignmentTypeEnum.LESSON;
		}
	}

	return assignmentTypeReturn;
}

function mapStringToSubjectEnum(subjectString: string): SubjectEnum {
	let subjectReturn: SubjectEnum;

	switch (subjectString.toUpperCase()) {
		case SubjectEnum.MATH: {
			subjectReturn = SubjectEnum.MATH;
			break;
		}
		case SubjectEnum.GEOGRAPHY: {
			subjectReturn = SubjectEnum.GEOGRAPHY;
			break;
		}
		case SubjectEnum.BIBLE: {
			subjectReturn = SubjectEnum.BIBLE;
			break;
		}
		case 'ELECTIVES':
		case SubjectEnum.ELECTIVES: {
			subjectReturn = SubjectEnum.ELECTIVES;
			break;
		}
		case SubjectEnum.LANGUAGE_ARTS: {
			subjectReturn = SubjectEnum.LANGUAGE_ARTS;
			break;
		}
		case SubjectEnum.SCIENCE: {
			subjectReturn = SubjectEnum.SCIENCE;
			break;
		}
		default: {
			console.warn('Unknown subject: ' + subjectString + ". Defaulting to 'ELECTIVE'");
			subjectReturn = SubjectEnum.ELECTIVES;
		}
	}

	return subjectReturn;
}

function calculateAssignmentState(activity: ActivityData): AssignmentStateEnum {
	let assignmentState = AssignmentStateEnum.UPCOMING;

	if (activity.isComplete) {
		assignmentState = AssignmentStateEnum.COMPLETED;
	} else if (activity.isOverdue) {
		assignmentState = AssignmentStateEnum.OVERDUE;
	} else if (activity.isStarted) {
		assignmentState = AssignmentStateEnum.STARTED;
	}

	// NOTE: The ILCardGroupAssignment will set cards to active if they are UPCOMING and first in group

	return assignmentState;
}

function courseDateRange(course?: CourseQuery, defaultDate = new Date()) {
	const dates = {
		startDate: startOfDay(defaultDate),
		endDate: endOfDay(defaultDate),
	};

	if (course) {
		if (course.startDate >= dates.startDate) {
			dates.startDate = course.startDate;
		}

		if (course.endDate >= dates.endDate) {
			dates.endDate = course.endDate;
		}
	}

	return dates;
}

function parseDate(input: string | Date, isHoliday = false): Date {
	let date = input as Date;
	if (typeof input === 'string') {
		const parts = input.split('-').map(Number);
		date = new Date(parts[0], parts[1] - 1, parts[2]);
	}
	// Holidays dates does not have timezone
	// We do not change timezone for those to not delay these dates
	if (isHoliday) {
		return date;
	}
	const userTimezoneOffset = date.getTimezoneOffset() * 60000;
	return new Date(date.getTime() - userTimezoneOffset);
}

function countActvities(arr: PlannerViewDayTeacherStudentsProps[]) {
	return arr
		.map((pv) => pv.courseListArgs.assignmentGroupArgs.map((agp) => agp.assignmentProps.length))
		.flat()
		.reduce((c, p) => c + p, 0);
}

function generateCountActivities(
	schoolSchedule: SchoolScheduleData,
	activities: ActivityOptionInterface[],
	selectedDate: Date,
	selectedStudents: OtkUserInterface[],
	todayDate = supplyNow()
) {
	const students = selectedStudents.map((st) => st.studentId || st.guid);
	const studentValues: StudentActivitiesEvents[] = [];

	Object.keys(schoolSchedule).forEach((k) => {
		if (students.includes(k)) {
			studentValues.push(schoolSchedule[k]);
		}
	});

	activities.forEach((a) => {
		if (a.activity.toLowerCase() === 'overdue') {
			a.count = isSameDay(selectedDate, todayDate)
				? studentValues.reduce((prev, curr) => prev + curr.overdueActivities.length, 0)
				: 0;
		} else if (a.activity.toLowerCase() === 'events') {
			a.count = studentValues
				.flatMap((st) => st.events)
				.filter((studentEvent) => {
					const startDate = parseDate(studentEvent.assignedDate, isHoliday(studentEvent));
					const endDate = parseDate(studentEvent.completedDate, isHoliday(studentEvent));
					return (
						startOfDay(startDate) <= endOfDay(selectedDate) &&
						startOfDay(selectedDate) <= endOfDay(endDate)
					);
				}).length;
		} else if (['quizzes', 'tests', 'resources', 'projects'].includes(a.activity.toLowerCase())) {
			a.count = countActvities(
				generateStudentAssignmentDayList(
					{
						students: selectedStudents,
						schoolSchedule: schoolSchedule,
					},
					generateStudentAssignmentDaySelections(selectedDate, [a.activity.toLowerCase()]),
					selectedDate
				)
			);
		} else {
			a.count = countActvities(
				generateStudentAssignmentDayList(
					{
						students: selectedStudents,
						schoolSchedule: schoolSchedule,
					},
					generateStudentAssignmentDaySelections(selectedDate, ['lessons']),
					selectedDate
				)
			);
		}
	});

	return activities;
}

export {
	courseDateRange,
	activityToCardAssignmentProps,
	calculateAssignmentState,
	generateStudentAssignmentDaySelections,
	generateStudentAssignmentDayList,
	generateStudentList,
	mapStringToAssignmentTypeEnum,
	mapStringToSubjectEnum,
	generateCountActivities,
	parseDate,
};

export type { StudentAssignmentDayListDataToMerge, StudentAssignmentDayListSelections };
